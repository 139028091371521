import Swal from 'sweetalert2';
import LangProvider from '../lang/lang-provider';
const _ = LangProvider.getConstants();

class ViewHandler {
  static showProgressBar(_isVisible) {
    if (_isVisible) {
      Swal.fire({
        title: _.alerts.loading,
        customClass: {
          title: 'title'
        },
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      });
    } else {
      Swal.close();
    }
  }

  static showMessage(_typeMessage, _title) {
    const config = {
      title: _title,
      type: _typeMessage,
      customClass: {
        title: 'viewHandler'
      },
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000
    };
    Swal.fire(config);
  }
}

export default ViewHandler;
