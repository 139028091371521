import view from '../ViewHandler';
const emailjs = require('emailjs-com');
const keys = require('./Keys.json');

class EmailService {
  static sendEmail(templateParams) {
    view.showProgressBar(true);
    return new Promise((resolve, reject) => {
      emailjs.init(keys.publicKey);
      emailjs.send(keys.serviceId, keys.templateId, templateParams).then(
        () => {
          view.showProgressBar(false);
          resolve();
        },
        () => {
          view.showProgressBar(false);
          reject();
        }
      );
    });
  }
}

export default EmailService;
