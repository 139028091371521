import React from 'react';
import ContactForm from '../../components/ContactForm';
import LangProvider from '../../lang/lang-provider';
const _ = LangProvider.getConstants();

const Contact = () => (
  <>
    <h3 className="text-5xl sm:xl font-semibold text-white">
      {_.contact.title}
    </h3>
    <p className="mt-8 text-xl font-light text-white">
      {_.contact.subtitle}
    </p>
    <div className="flex justify-center mt-10">
      <ContactForm />
    </div>
  </>
);

export default Contact;
