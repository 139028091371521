import React from 'react';
import Button from './Button';
import View from '../services/ViewHandler';
import EmailService from '../services/email-services/EmailService';
import LangProvider from '../lang/lang-provider';
const _ = LangProvider.getConstants();

const ContactForm = () => {
  const sendEmail = () => {
    if (
      document.getElementById('grid-name').value &&
      document.getElementById('grid-email').value &&
      document.getElementById('grid-phone').value &&
      document.getElementById('grid-message').value
    ) {
      EmailService.sendEmail({
        from_customer: document.getElementById('grid-name').value,
        email_customer: document.getElementById('grid-email').value,
        phone_customer: document.getElementById('grid-phone').value,
        message_customer: document.getElementById('grid-message').value
      })
        .then(() => {
          View.showMessage(
            'success',
            _.alerts.email_successful
          );
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch(() => {
          View.showMessage('error', _.alerts.error_default);
        });
    } else {
      View.showMessage('warning', _.alerts.empty_fields);
    }
  };

  return (
    <form className="w-full max-w-lg pl-6 pr-6">
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-name"
          >
            {_.contact.form.name}
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-name"
            type="text"
            placeholder="Tu nombre"
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-email"
          >
            {_.contact.form.email}
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="grid-email"
            type="email"
            placeholder="Tu correo"
          />
        </div>
        <div className="w-full md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-phone"
          >
            {_.contact.form.phone}
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-phone"
            type="number"
            placeholder="000 000 00 00"
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label
            className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
            htmlFor="grid-message"
          >
            {_.contact.form.message}
          </label>
          <textarea
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
            id="grid-message"
            type="text"
            placeholder={_.contact.form.messagePlaceholder}
            rows="6"
          />
        </div>
      </div>
      <Button size="lg" event={sendEmail}>
        {_.button.send}
      </Button>
    </form>
  );
};

export default ContactForm;
